import { useState } from "react";
import PropTypes from "prop-types";
import { S } from "../styles";
import { UiText } from "@heetch/flamingo-react";
import { Wizard } from "../wizard/Wizard";

const NotFound = ({ driverUid }) => {
  const [readyToDisplayWizard, setReadyToDisplayWizard] = useState(false);
  return (
    <div>
      {!readyToDisplayWizard ? (
        <S.Status>
          <S.Emoji>👋</S.Emoji>
          <S.StateTitle level={1}>Bienvenue</S.StateTitle>
          <UiText variant="content">
            Il reste une dernière étape avant de pouvoir conduire, vérifiez
            votre permis de conduire et votre carte VTC
          </UiText>
          <S.StepButton
            type="button"
            intent="secondary"
            onClick={() => setReadyToDisplayWizard(true)}
          >
            Commencer
          </S.StepButton>
        </S.Status>
      ) : (
        <Wizard driverUid={driverUid} />
      )}
    </div>
  );
};

NotFound.propTypes = {
  driverUid: PropTypes.string.isRequired,
};

export { NotFound };
