import PropTypes from "prop-types";
import { useState } from "react";
import { differenceInDays, format } from "date-fns";
import { UiText } from "@heetch/flamingo-react";
import { Wizard } from "../wizard/Wizard";
import { S } from "../styles";

const Processed = ({ updated_at, driverUid }) => {
  const [readyToDisplayWizard, setReadyToDisplayWizard] = useState(false);
  return (
    <div>
      {!readyToDisplayWizard ? (
        <S.Status>
          <S.Emoji>✅</S.Emoji>
          <S.StateTitle level={1}>documents validés</S.StateTitle>
          <UiText variant="content">
            Vous pouvez désormais conduire sur Heetch !
          </UiText>
          {differenceInDays(
            new Date(),
            new Date(format(new Date(updated_at), "yyyy-MM-dd")),
          ) > 7 && (
            <>
              <UiText variant="content">
                Vous avez besoin de recommencer la vérification de documents ?
              </UiText>
              <S.StepButton
                type="button"
                intent="secondary"
                onClick={() => setReadyToDisplayWizard(true)}
              >
                Recommencer
              </S.StepButton>
            </>
          )}
        </S.Status>
      ) : (
        <Wizard driverUid={driverUid} />
      )}
    </div>
  );
};

Processed.propTypes = {
  updated_at: PropTypes.string.isRequired,
  driverUid: PropTypes.string.isRequired,
};

export { Processed };
