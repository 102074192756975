import { useEffect, useState } from "react";
import {
  Processing,
  Processed,
  Error,
  Failed,
  Started,
  NotFound,
  Aborted,
  Expired,
} from "./components/status";
import { Spinner } from "@heetch/flamingo-react";
import "@heetch/flamingo-react/dist/styles.css";
import logo from "./assets/heetch-icon.png";
import { UBBLE_STATUS } from "./constants";
import { S } from "./components/styles";

const App = () => {
  const driverUid = new URLSearchParams(window.location.search).get("uid");
  const [statusInfo, setStatusInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let mounted = true;
    const fetchStatus = async () => {
      if (!driverUid) {
        setIsError("Invalid parameters");
        setIsLoading(false);
        return;
      }
      try {
        const result = await fetch(
          `${process.env.REACT_APP_API_HOST}/api/solutions/ubble/status?uid=${driverUid}`,
        );
        if (mounted) {
          if (result?.status === 400) {
            setIsError("Invalid parameters");
          } else if (result?.status === 404) {
            setStatusInfo({ status: UBBLE_STATUS.NOT_FOUND });
          } else if (result?.status === 200) {
            const body = await result.json();
            setStatusInfo(body);
          } else {
            setIsError("Network error");
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsError("Network error");
        setIsLoading(false);
      }
    };
    if (isLoading) {
      fetchStatus();
    }
    return function cleanup() {
      mounted = false;
    };
  }, [driverUid, isError, isLoading]);

  return (
    <div>
      <S.Header>
        <S.HeaderImg src={logo} alt="Heetch" />
        <S.HeaderTitle level={3}>Vérification d’identité</S.HeaderTitle>
      </S.Header>
      <S.Divider />
      <div className="App">
        {isLoading && <Spinner />}
        {isError && <Error error={isError} />}
        {statusInfo?.status === UBBLE_STATUS.NOT_FOUND && driverUid && (
          <NotFound driverUid={driverUid} />
        )}
        {(statusInfo?.status === UBBLE_STATUS.START ||
          statusInfo?.status === UBBLE_STATUS.UNINITIATED) &&
          statusInfo?.identification_url && (
            <Started ubbleIdentificationUrl={statusInfo?.identification_url} />
          )}
        {statusInfo?.status === UBBLE_STATUS.ABORTED && driverUid && (
          <Aborted driverUid={driverUid} />
        )}
        {statusInfo?.status === UBBLE_STATUS.EXPIRED && driverUid && (
          <Expired driverUid={driverUid} />
        )}
        {(statusInfo?.status === UBBLE_STATUS.SUCCESS ||
          statusInfo?.status === UBBLE_STATUS.FORCE_VALIDATED) &&
          statusInfo?.updated_at &&
          driverUid && (
            <Processed
              updated_at={statusInfo?.updated_at}
              driverUid={driverUid}
            />
          )}
        {(statusInfo?.status === UBBLE_STATUS.UBBLE_PROCESSED ||
          statusInfo?.status === UBBLE_STATUS.PROCESSING) && <Processing />}
        {statusInfo?.status === UBBLE_STATUS.REJECTED && driverUid && (
          <Failed driverUid={driverUid} />
        )}
      </div>
    </div>
  );
};

export default App;
