import { useState } from "react";
import PropTypes from "prop-types";
import { UiText } from "@heetch/flamingo-react";
import { Error } from "../../status/Error";
import Bad from "../../../assets/step3-bad.gif";
import Good from "../../../assets/step3-good.gif";
import { S } from "../../styles";

const Step3 = ({ driverUid, setCurrentStep }) => {
  const [isError, setIsError] = useState(false);
  const [ubbleIdentificationUrl, setUbbleIdentificationUrl] = useState();

  const fetchStatus = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/solutions/ubble/create?uid=${driverUid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );
      if (result?.status === 201) {
        const body = await result.json();
        setUbbleIdentificationUrl(body.identification_url);
      } else if (result?.status === 400) {
        setIsError("Invalid parameters");
      } else {
        setIsError("Network error");
      }
    } catch (error) {
      setIsError("Network error");
    }
  };

  return (
    <>
      {isError ? (
        <Error error={isError} />
      ) : (
        <S.Step isStep3>
          <S.Emoji sun>📷</S.Emoji>
          <UiText variant="contentBold" as="div">
            <p>
              Vous allez devoir utiliser la caméra de votre smartphone pour
              filmer votre permis et carte VTC.
            </p>
            <p>Veillez à ce qu’on puisse : </p>
            <p>
              <UiText variant="contentBlack" as="span">
                • voir l’intégralité de votre document,
              </UiText>{" "}
              y compris ses bords
            </p>
            •{" "}
            <UiText variant="contentBlack" as="span">
              lire nettement
            </UiText>{" "}
            les informations écrites
            <p>
              S’il manque un morceau de votre document dans la vidéo ou qu’il
              n’est pas lisible,{" "}
              <UiText variant="contentBlack" as="span">
                il sera refusé !{" "}
              </UiText>
            </p>
          </UiText>
          <S.GoodBadImg>
            <S.StepImg isStep2 src={Good} alt="good-practice" />
            <S.StepImg isStep2 src={Bad} alt="bad-practice" left />
          </S.GoodBadImg>
          <div style={{ display: "inline-flex", marginTop: "32px" }}>
            <S.StepButton
              type="button"
              isSmall
              intent="secondary"
              variant="minimal"
              onClick={() => setCurrentStep(2)}
            >
              Précedent
            </S.StepButton>
            <S.StepButton
              type="button"
              isSmall
              intent="secondary"
              onClick={() => fetchStatus()}
            >
              J’ai compris
            </S.StepButton>
          </div>
        </S.Step>
      )}
      {ubbleIdentificationUrl && window.location.assign(ubbleIdentificationUrl)}
    </>
  );
}

Step3.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  driverUid: PropTypes.string.isRequired,
};
export { Step3 };
