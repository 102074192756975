import PropTypes from "prop-types";
import { S } from "../../styles";
import Smartphone from "../../../assets/smartphone.png";
import DrivingLicense from "../../../assets/driving-license.png";
import VtcCard from "../../../assets/vtc-card.png";
import { UiText } from "@heetch/flamingo-react";

const Step1 = ({ setCurrentStep }) => (
  <S.Step>
    <UiText variant="contentBold" style={{ margin: "0" }}>
      Veuillez vous munir de votre :
    </UiText>
    <S.Step1Documents>
      <S.Step1License>
        <UiText variant="contentBlack">• Permis de conduire</UiText>
        <S.StepImg src={DrivingLicense} alt="driving-license" />
      </S.Step1License>
      <S.Step1Vtc>
        <UiText variant="contentBlack">• Carte VTC</UiText>
        <S.StepImg src={VtcCard} alt="vtc-card" />
      </S.Step1Vtc>
      <S.Step1Smartphone>
        <UiText variant="contentBlack">• Smartphone</UiText>
        <S.StepImg src={Smartphone} alt="smartphone" />
      </S.Step1Smartphone>
    </S.Step1Documents>
    <S.StepButton
      type="button"
      intent="secondary"
      onClick={() => setCurrentStep(2)}
    >
      Suivant
    </S.StepButton>
  </S.Step>
);

Step1.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
};

export { Step1 };
