import { useState } from "react";
import PropTypes from "prop-types";
import { S } from "../styles";
import { Wizard } from "../wizard/Wizard";
import { UiText } from "@heetch/flamingo-react";

const Failed = ({ driverUid }) => {
  const [readyToDisplayWizard, setReadyToDisplayWizard] = useState(false);
  return (
    <div>
      {!readyToDisplayWizard ? (
        <S.Status>
          <S.Emoji>❌</S.Emoji>
          <S.StateTitle level={1}>documents refusés</S.StateTitle>
          <UiText variant="content">
            Recommencez et essayez de bien appliquer les instructions pour
            réussir la procédure.
          </UiText>
          <S.StepButton
            type="button"
            intent="secondary"
            onClick={() => setReadyToDisplayWizard(true)}
          >
            Recommencer
          </S.StepButton>
        </S.Status>
      ) : (
        <Wizard driverUid={driverUid} />
      )}
    </div>
  );
};

Failed.propTypes = {
  driverUid: PropTypes.string.isRequired,
};

export { Failed };
