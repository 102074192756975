import styled from "styled-components/macro";
import { Heading, Button } from "@heetch/flamingo-react";

const HeaderTitle = styled(Heading)`
  color: var(--f-color-text--tertiary);
  font-size: 16px;
`;

const Header = styled.div`
  display: inline-flex;
  margin: 13px 0px;
  align-items: center;
`;

const HeaderImg = styled.img`
  margin: 0px 17px;
  height: 30px;
`;

const StateTitle = styled(Heading)`
  margin-bottom: 0;
  font-size: 36px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #dde3ee;
`;

const Emoji = styled.div`
  margin-bottom: ${({ sun }) => (sun ? "0px" : "16px")};
  height: ${({ sun }) => (sun ? "112px" : "72px")};
  align-items: center;
  font-size: ${({ sun }) => (sun ? "112px" : "72px")};
  display: flex;
  @media (max-width: 1080px) {
    justify-content: ${({ sun }) => sun && "center"};
  }
`;

const Status = styled.div`
  text-align: initial;
  margin: 0px 16px;
  height: 100%;
  margin-top: 50%;
  @media (min-width: 1080px) {
    margin-left: 170px;
    margin-top: 252px;
    width: 542px;
  }
`;

const StepButton = styled(Button)`
  height: 60px;
  margin: 0px;
  width: ${({ isSmall }) => isSmall && "100%"};
  @media (min-width: 1080px) {
    width: ${({ isSmall }) => (isSmall ? "171.5px" : "343px")};
  }
  > span {
    font-size: 18px;
  }
`;

const Step1Documents = styled.div`
  @media (min-width: 1080px) {
    display: inline-flex;
    margin-bottom: 32px;
  }
`;

const Step1Vtc = styled.div`
  @media (max-width: 1080px) {
    > .UiText {
      width: 100%;
    }
    > img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 1080px) {
    width: 140px;
    margin-right: 48px;
  }
`;

const Step1License = styled.div`
  @media (max-width: 1080px) {
    > .UiText {
      width: 100%;
    }
    > img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 1080px) {
    width: 170px;
    margin-right: 48px;
  }
`;

const Step1Smartphone = styled.div`
  @media (max-width: 1080px) {
    > .UiText {
      width: 100%;
    }
    > img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 1080px) {
    width: 117px;
    margin-right: 48px;
  }
`;

const GoodBadImg = styled.div`
  @media (min-width: 1080px) {
    display: inline-flex;
  }
`;

const Step = styled.div`
  display: grid;
  text-align: initial;
  margin: 16px;
  @media (max-width: 1080px) {
    height: 100vh;
  }
  @media (min-width: 1080px) {
    display: grid;
    margin-left: 170px;
    margin-top: ${({ isStep2, isStep3 }) =>
      isStep2 || isStep3 ? "50px" : "252px"};
    width: 542px;
  }
`;

const StepImg = styled.img`
  @media (min-width: 1080px) {
    margin-left: ${({ left }) => left && "16px"};
  }
  @media (max-width: 1080px) {
    width: ${({ isStep2 }) => isStep2 && "100%"};
  }
`;

const S = {
  Header,
  HeaderImg,
  HeaderTitle,
  StateTitle,
  Divider,
  Emoji,
  Status,
  StepButton,
  Step,
  Step1Vtc,
  Step1License,
  Step1Smartphone,
  StepImg,
  Step1Documents,
  GoodBadImg,
};

export { S };
