export const UBBLE_STATUS = {
  UNINITIATED: "uninitiated",
  START: "start",
  SUCCESS: "success",
  FORCE_VALIDATED: "forced_validated",
  UBBLE_PROCESSED: "ubble_processed",
  PROCESSING: "processing",
  REJECTED: "rejected",
  ABORTED: "aborted",
  EXPIRED: "expired",
  NOT_FOUND: "not_found",
};
