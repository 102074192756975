import { UiText } from "@heetch/flamingo-react";
import { S } from "../styles";

const Processing = () => (
  <div>
    <S.Status>
      <S.Emoji>⏳</S.Emoji>
      <S.StateTitle level={1}>en cours de vérification</S.StateTitle>
      <UiText variant="content">
        Nous avons bien reçu vos documents filmés et nous allons procéder à leur
        vérification. Ceci peut prendre jusqu’à 48h.
      </UiText>
    </S.Status>
  </div>
);

export { Processing };
