import { useState } from "react";
import PropTypes from "prop-types";
import { S } from "../styles";
import { Wizard } from "../wizard/Wizard";
import { UiText } from "@heetch/flamingo-react";

const Expired = ({ driverUid }) => {
  const [readyToDisplayWizard, setReadyToDisplayWizard] = useState(false);
  return (
    <div>
      {!readyToDisplayWizard ? (
        <S.Status>
          <S.Emoji>😳</S.Emoji>
          <S.StateTitle level={1}>Lien expiré</S.StateTitle>
          <UiText variant="content">
            Ce lien n’est plus valide, veuillez recommencer une nouvelle
            procédure de vérification.
          </UiText>
          <S.StepButton
            type="button"
            intent="secondary"
            onClick={() => setReadyToDisplayWizard(true)}
          >
            Recommencer
          </S.StepButton>
        </S.Status>
      ) : (
        <Wizard driverUid={driverUid} />
      )}
    </div>
  );
};

Expired.propTypes = {
  driverUid: PropTypes.string.isRequired,
};

export { Expired };
