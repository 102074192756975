import PropTypes from "prop-types";
import { UiText } from "@heetch/flamingo-react";
import Bad from "../../../assets/bad.png";
import Good from "../../../assets/good.png";
import { S } from "../../styles";

const Step2 = ({ setCurrentStep }) => (
  <S.Step isStep2>
    <S.Emoji sun>🔆</S.Emoji>
    <UiText variant="contentBold">
      Assurez-vous d’être dans un{" "}
      <UiText variant="contentBlack" as="span">
        endroit lumineux
      </UiText>
      , suffisamment pour qu’on puisse voir des{" "}
      <UiText variant="contentBlack" as="span">
        reflets sur le plastique
      </UiText>{" "}
      de vos documents. Le processus ne fonctionnera pas autrement !
    </UiText>
    <S.GoodBadImg>
      <S.StepImg isStep2 src={Good} alt="good-environement" />
      <S.StepImg isStep2 src={Bad} alt="bad-environement" left />
    </S.GoodBadImg>
    <div style={{ display: "inline-flex", marginTop: "32px" }}>
      <S.StepButton
        type="button"
        isSmall
        intent="secondary"
        variant="minimal"
        onClick={() => setCurrentStep(1)}
      >
        Précedent
      </S.StepButton>
      <S.StepButton
        type="button"
        isSmall
        intent="secondary"
        onClick={() => setCurrentStep(3)}
      >
        Suivant
      </S.StepButton>
    </div>
  </S.Step>
);

Step2.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
};
export { Step2 };
