import { useState } from "react";
import PropTypes from "prop-types";
import { Step1, Step2, Step3 } from "./components";

const Wizard = ({ driverUid }) => {
  const [currentStep, setCurrentStep] = useState(1);
  return (
    <>
      {currentStep === 1 && <Step1 setCurrentStep={setCurrentStep} />}
      {currentStep === 2 && <Step2 setCurrentStep={setCurrentStep} />}
      {currentStep === 3 && (
        <Step3 setCurrentStep={setCurrentStep} driverUid={driverUid} />
      )}
    </>
  );
};

Wizard.propTypes = {
  driverUid: PropTypes.string,
};
export { Wizard };
