import PropTypes from "prop-types";
import { S } from "../styles";
import { UiText } from "@heetch/flamingo-react";

const Error = ({ error }) => (
  <div>
    <S.Status>
      <S.Emoji>😳</S.Emoji>
      {error === "Network error" ? (
        <>
          <S.StateTitle level={1}>
            Notre service de vérification d’identité rencontre des problèmes
            actuellement.
          </S.StateTitle>
          <UiText variant="content">
            Veuillez réessayer plus tard ou contacter notre support.
          </UiText>
        </>
      ) : (
        <>
          <S.StateTitle level={1}>Cette page n’existe pas</S.StateTitle>
          <UiText variant="content">
            Désolé, le lien que vous utilisez semble non valide.
          </UiText>
        </>
      )}
      <S.StepButton
        type="button"
        intent="secondary"
        onClick={() =>
          window.location.assign(
            "https://help.heetch.com/hc/fr/requests/new?ticket_form_id=360000216520&request_type=signup____application&section_id=360001654520",
          )
        }
      >
        Contacter le support
      </S.StepButton>
    </S.Status>
  </div>
);

Error.propTypes = {
  error: PropTypes.string,
};

export { Error };
