import PropTypes from "prop-types";
import { S } from "../styles";
import { UiText } from "@heetch/flamingo-react";

const Started = ({ ubbleIdentificationUrl }) => (
  <div>
    <S.Status>
      <S.Emoji>😳</S.Emoji>
      <S.StateTitle level={1}>Vous avez quitté le processus.</S.StateTitle>
      <UiText variant="content">
        Veuillez reprendre la vérification en cours.
      </UiText>
      <S.StepButton
        type="button"
        intent="secondary"
        onClick={() => window.location.assign(ubbleIdentificationUrl)}
      >
        Reprendre
      </S.StepButton>
    </S.Status>
  </div>
);

Started.propTypes = {
  ubbleIdentificationUrl: PropTypes.string.isRequired,
};

export { Started };
